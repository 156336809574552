import React from 'react';
import App from 'next/app';
import { InstantSearch } from 'react-instantsearch-dom';
import { getSearchClient } from '../adapters/algolia/client';
import '../styles/index.scss';
import { TabProvider } from '../components/TabContext/TabContext';

class CustomApp extends App {
    render() {
        const { Component, pageProps } = this.props;
        const searchClient = getSearchClient();
        return (
            <TabProvider>
                <InstantSearch searchClient={searchClient} indexName={process.env.ALGOLIA_INDEX}>
                    <Component {...pageProps} />
                </InstantSearch>
            </TabProvider>
        )
    }
}

export default CustomApp;
